<template>
  <helmet-provider>
    <helmet-source>
      <slot/>
    </helmet-source>
  </helmet-provider>
  
</template>

<script>
  import { Helmet, HelmetProvider } from "@jnields/vue-helmet"

  export default {
    name: 'Helmet',
    components: {
      HelmetSource: Helmet,
      HelmetProvider,
    }
  }
</script>

<style scoped lang="stylus">

</style>